import { Membro } from './membro.model';


export class Usuario extends Membro {

  login?: string;
  senha?: string;
  dataExpiracao?: string;
  codigoPerfil?: string;
  dataInicial?: string;
 statusPerfil?: string;
 editorPerfil?: string;
 atualizadoPerfil?: string;
 tipo?: string;
 permissoes?: any[];

  constructor () {
    super();

  }

  static fromJson(jsonData: any): Usuario {
    return Object.assign(new Usuario(), jsonData);
  }
}
