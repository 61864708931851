// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
 // ao usar o proxy essa variavel foi alterada URL_PADRAO: 'http://localhost:8090/perfil/'
// URL_PADRAO: '/api'
//URL_PADRAO: 'http://localhost:8080/'
//URL_PADRAO: 'http://localhost:8090/perfil/'
// URL_PADRAO: 'https://perfilmembro-api.herokuapp.com/'
URL_PADRAO: 'https://perfil-filial-api.herokuapp.com/'
};
