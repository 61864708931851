import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { HttpClientModule, HttpClient} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SlickModule } from 'ngx-slick';
import { DirectivesModule } from './core/directive/directives.module';
import { AgmCoreModule } from '@agm/core';
/* Routing */
import { AppRoutingModule } from './app-routing.module';

/* Service */
import { ChkService } from './service/chk.service';

/* components */
import { AppComponent } from './app.component';
import { MainComponent }   from './main/main.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';

import { MenuItems } from './core/menu/menu-items/menu-items';
import { MenuToggleModule } from './core/menu-toggle.module';
import { PageTitleService } from './core/page-title/page-title.service';
import { WidgetsModule } from './widgets/widgets.module';
import { AboutComponent } from './about/about.component';
import { SearchComponent } from './search/search.component';
import { Footer2Component } from './footer2/footer2.component';
import { sidebarWidgetsComponent } from './sidebarWidgets/sidebarWidgets.component';

// Refatoração de sistema
import { AuthService } from './session/service/auth.service';
import { UsuarioService } from './service/usuario.service';

import { JwtModule } from '@auth0/angular-jwt';

import { TokenInterceptor } from './interceptador/interceptor';
import { AuthGuard } from './guardiao/auth.guard';

@NgModule({
   declarations: [
      AppComponent,
      MainComponent,
      HomeComponent,
      HeaderComponent,
      MenuComponent,
      AboutComponent,
      SearchComponent,
      Footer2Component,
      sidebarWidgetsComponent,
   ],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,
      AppRoutingModule,
      WidgetsModule,
      MenuToggleModule,
      DirectivesModule,
      SlickModule.forRoot(),
      AgmCoreModule.forRoot({
         apiKey: 'AIzaSyD4y2luRxfM8Q8yKHSLdOOdNpkiilVhD9k'
      })
   ],
   providers: [
      MenuItems,
      PageTitleService,
      ChkService,
      AuthService,
      UsuarioService,
      //NotificacaoService,
      AuthGuard

   ],
   bootstrap: [AppComponent]
})
export class AppModule { }
