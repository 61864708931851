import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { MainComponent }   from './main/main.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { SearchComponent } from './search/search.component';
import { sidebarWidgetsComponent } from './sidebarWidgets/sidebarWidgets.component';
import { AuthGuard } from './guardiao/auth.guard';

export const AppRoutes: Routes = [{
   path: '',
   redirectTo: 'home',
   pathMatch: 'full',
   },{
      path: '',
      component: MainComponent,
      children: [
         {
            path: 'home/membroModificado',
            component: HomeComponent, canActivate: [AuthGuard]
         },         {
            path: 'home',
            component: HomeComponent, canActivate: [AuthGuard]
         },{
            path:'about',
            component:AboutComponent
         },{
            path:'search',
            component:SearchComponent
         }, {
            path: 'sidebar-widgets',
            component:sidebarWidgetsComponent
         },{
            path: '',
            loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
         },{
            path: '',
            loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
         },{ 
				path: 'about/:keyword',component: AboutComponent 
			}
      ]
}];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(AppRoutes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
