import { Component, OnInit, OnDestroy, ViewChild, HostListener, Inject, ElementRef, ViewEncapsulation } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
declare var $ : any;

import { MembroService } from '../service/membro.service';
import { UsuarioService } from '../service/usuario.service';
import { Usuario } from '../model/usuario';
import { Membro } from '../model';

@Component({
    selector: 'angly-layout',
  	templateUrl:'./main.component.html',
  	styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit{

	 private _router: Subscription;
	 url: string;
	 private _mediaSubscription: Subscription;
	 private _routerEventsSubscription: Subscription;
	 isHome : boolean = true;
	 isBlog : boolean = false;
	 isCommon : boolean = false;
	 fixedHeaderClass : boolean = false;
	
	/* Variables */
	headerTitle    : string;
	headerSubTitle : string;
	featuredPost   : any;

	nome: string;
	expiracao: string;
	login: string;
	membro: Membro;
	usuarioRede: Usuario;
	
	constructor(private pageTitleService: PageTitleService, private service:ChkService, public router: Router,
		private membroService: MembroService, private usuarioService: UsuarioService,private activatedRoute: ActivatedRoute ) {
	
		/* page title.*/
			this.pageTitleService.title.subscribe((val: string) => {
			this.headerTitle = val;
		});

		/* page subTitle. */
			this.pageTitleService.subTitle.subscribe((val: string) => {
			this.headerSubTitle = val;
		});

		this.service.getFeaturedPost().
			subscribe(response => {this.featuredPost = response},
			          err    => console.log(err),
			          ()     => this.featuredPost
			       );
				console.log("pegando a rota..." );
				  console.log(this.router.url) ;
				  this.activatedRoute.queryParams
				  .filter(params => params.order)
				  .subscribe(params => {
					console.log(params); // { order: "popular" }
			
					this.buscarRegistroModificado(params.order);
				//	
				  }
				);
	
	}

	
    ngOnInit() {
		const bancoMembro = localStorage.getItem('membro');
		//salvarDadosModificadosLocal
  
		  setTimeout(() => {
			if (bancoMembro === undefined || null === bancoMembro) {
				this.buscarRegistro();
				//this.membro = JSON.parse(bancoMembro);
			  }
			console.log('ATENCAO MAIN IS RUNNING IN INIT');

		  }, 2000);
	  }
  
	  buscarRegistro() {
  
		  console.log('REDE SER NAO ENCONTRADO ');
  
			const matricula = this.apenasNumeros(localStorage.getItem('login'));
			const bancoMembro = localStorage.getItem('membro');
  
			if (bancoMembro === undefined || null === bancoMembro) {
				console.log('banco vazio');
			}
			this.membroService.getById(matricula).subscribe(
			  membroRemoto => {
			  this.membro = membroRemoto;
				this.nome = this.membro.nome;
				this.membroService.mostrarMembroEmitter.emit(membroRemoto);
				this.verificarExpiracao();
				this.membroService.salvarDadosLocal(membroRemoto);
			console.log('membro = ' + JSON.stringify(membroRemoto));
			},
			  error => {
				return alert('Erro ao carregar a lista');
			  }
			);
  
			this.membroService.setApiPathDefault();
	  }
  
	  buscarRegistroModificado(rota: string){
		console.log("buscarRegistroModificado =>" + rota); // popular
		if (rota ==="modificado"){
			const bancoMembro = localStorage.getItem('membroModificado');
			this.membro = JSON.parse(bancoMembro);
			this.membroService.mostrarMembroEmitter.emit(this.membro);
		}
	  }

	  verificarExpiracao() {
		const matricula = localStorage.getItem('matricula');
	   this.usuarioService.statusExpiracao(matricula);
  
	  }
  
	
	@HostListener('scroll', ['$event'])
	onScroll(event) {
		  if(event.path && (event.path[0].scrollTop > 0)){
		  		this.fixedHeaderClass = true;
		  }else{
		  		this.fixedHeaderClass = false;
		  }
	}

	onActivate(e, scrollContainer) {
		scrollContainer.scrollTop = 0;
		window.scroll(0,0);
   }

   addToggleClass() {
   	$('body').toggleClass('rtl-enable');
   }

   apenasNumeros(login: string): number {
	if (login !== undefined && login.trim() !== '') {
		const mat =  login.replace(/[^0-9 ]/g,'');
		return Number.parseInt(mat);
	} else {
	 	alert('navegue para login');
	}
	}

	armazenarDadosLocal(nomeBanco: string, dados: any) {
		localStorage.setItem(nomeBanco, dados);
	}
}
