import { BaseResourceModel } from '../shared/models/base-resource.model';

/**
 * Classe foi utilizada tambem no ebenezer financeiro
 */
export class Membro extends BaseResourceModel {

  constructor (
    public idMembro?: number,
    public nome?: string,
    public matricula?: number,
    public sexo?: string,
    public nomeMae?: string,
    public nomePai?: string,
    public dataNascimento?: string,
    public estadoCivil?: string,
    public nacionalidade?: string,
    public naturalidade?: string,
    public rg?: string,
    public rgDataExpedicao?: string,
    public rgOrgaoExpeditor?: string,
    public cpf?: string,
    public cpfDataExpedicao?: string,
    public escolaridade?: string,
    public profissao?: string,
    public editorRegistro?: string,
    public dataAtualizacaoRegistro?: string,
    public idEndereco?: number,
    public logradouro?: string,
    public complemento?: string,
    public bairro?: string,
    public cidade?: string,
    public estado?: string,
    public pais?: string,
    public uf?: string,
    public cep?: string,
    public idContato?: number,
    public telefone1?: string,
    public telefone2?: string,
    public celular?: string,
    public email?: string,
    public idReligioso?: number,
    public dataValidadeCarteira?: string,
    public dataEmissaoCarteira?: string,
    public filial?: string,
    public cargo?: string,
    public funcao?: string,
    public observacao?: string,
    public isCarteiraImpressa?: string,
    public situacaoMembro?: string,
    public dataBatizado?: string,
    public dataBatizadoEspiritoSanto?: string,
    public isBatizado?: string,
    public formaAdmissao?: string,
    public igrejaAnterior?: string,
    public dataAdmissao?: string,
    public isBatizadoEspiritoSanto?: string,
    public codigoCgadb?: string,
    public codigoConfraderj?: string,
  ) {
    super();
  }

  static fromJson(jsonData: any): Membro {
    return Object.assign(new Membro(), jsonData);
  }
}
