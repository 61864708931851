import { Membro } from './../model/membro.model';
import { Injectable, Injector, EventEmitter } from '@angular/core';
import { BaseResourceService } from '../shared/services/base-resource.service';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment} from './../../environments/environment'; // environments/environment';
import { MatriculaTemporaria } from '../model/matricula-temporaria';


@Injectable({
  providedIn: 'root'
})
export class MembroService  extends BaseResourceService<Membro> {

  membro: Membro;

  mostrarMembroEmitter = new EventEmitter<Membro>();

  constructor(protected injector: Injector) {
    super('v1/membro', injector, Membro.fromJson);
  }

  getAll2(arrayMatriculas: String): Observable<Membro[]> {
   // this.setApiPath("http://localhost:8080/v1/membro")
    return this.http.get(this.apiPath + "?" + arrayMatriculas).pipe(
      map(this.jsonDataToResources.bind(this)),
      catchError(this.handleError)
    );
  }

  getMatriculaTemporaria(): Observable<MatriculaTemporaria> {
    // this.setApiPath("http://localhost:8080/v1/membro")
     return this.http.get(this.apiPath).pipe(
       map(this.jsonDataToResource.bind(this)),
       catchError(this.handleError)
     );
   }

  update2(resource: Membro, matricula: any): Observable<Membro> {
   const login = localStorage.getItem("login");
   const param = "?editor=" + login;
    const url = `${this.apiPath}/${matricula}${param}`;
    console.log(resource);
    return this.http.put(url, resource).pipe(
      map(() => resource),
      catchError(this.handleError)
    );
  }

  getByNotification(id: number): Observable<Membro>{
  //  this.setApiPath("http://localhost:8080/v1/membroModificado");

    const url = `${this.apiPath}/${id}`;

    return this.http.get(url).pipe(
      map(this.jsonDataToResource.bind(this)),
      catchError(this.handleError)
    );

  }

  recuperarDadosLocal(): Membro {

    const json = localStorage.getItem('membro');
    if (json) {
      this.membro =  JSON.parse(json);
    }

    return this.membro;
  }

  salvarDadosLocal(dados) {
    localStorage.setItem('membro', JSON.stringify(dados));
  }

  salvarDadosModificadosLocal(dados) {
    localStorage.setItem('membroModificado', JSON.stringify(dados));
  }
}
