

import { Router } from '@angular/router';
import { Injectable, EventEmitter } from '@angular/core';
import { Usuario } from '../../model/usuario';
import { UsuarioService } from './../../service/usuario.service';

@Injectable()
export class AuthService {
  // tslint:disable-next-line:no-inferrable-types
  private usuarioAutenticado: boolean = false;

  mostrarMenuEmitter = new EventEmitter<boolean>();


  constructor(private router: Router) {}

  fazerLogin(usuario: Usuario) {
    if (usuario.login === localStorage.getItem('login') ) {
      this.usuarioAutenticado = true;
      this.mostrarMenuEmitter.emit(true);
      this.router.navigate(['/']);
    } else {
      this.usuarioAutenticado = false;
      this.mostrarMenuEmitter.emit(false);
    }
  }

  usuarioEstaAutenticado() {
    const autenticado =  this.usuarioAutenticado;
    console.log('usuarioEstaAutenticado() = ' + autenticado);
    return autenticado;
   // return this.usuarioAutenticado;
  }
}


