export class Religioso {

  public id?: number;
  public fkCodigo: number;
  public dataValidadeCarteira: string;
  public dataEmissaoCarteira: string;
  public filial: string;
  public cargo: string;
  public funcao: string;
  public observacao: string;
  public statusImpressao: string;
  public situacaoMembro: string;
  public dataBatizado: string;
  public dataBatizadoEspiritoSanto: string;
  public batizado: string;
  public formaAdmissao: string;
  public igrejaAnterior: string;
  public atualizacao: string;
  public dataAdmitido: string;
  public batizadoEspiritoSanto: string;
  public codigo_CGADB: string;
  public codigo_Confraderj: string;

  constructor() {}
}
