import { Usuario } from './../model/usuario';
import { Injectable, Injector, EventEmitter } from '@angular/core';
import { BaseResourceService } from '../shared/services/base-resource.service';
import { AuthService } from '../session/service/auth.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService  extends BaseResourceService<Usuario> {

  private jwtPayload: any;
  private helper = new JwtHelperService();
  private usuario: Usuario;

  private interTime: any;
  private contador = 1;
  private statusUsuario: string;
  mostrarUsuarioStatusEmitter = new EventEmitter<String>();

  constructor(protected authService: AuthService, private router: Router, protected injector: Injector) {
    super('oauth/token', injector, Usuario.fromJson);
  }

  descriptografar_Logar(idRota: any) {
    // o sistema lançou uma exceçao quando o idRota estava errado... tratar isso.
    const decodeLogin = atob(idRota);
    const login: string[] = decodeLogin.split('&');

    if (login) {

        const usuario = new Usuario();
        usuario.login = login[0];
        usuario.senha = login[1];

        console.log(usuario);
        this.logarRede(usuario);

    } else {

        this.router.navigate(['/login']);
    }
}

logarRede(usuario: Usuario): Promise<void> {
   this.apagarToken();
   this.apagarVariaveis();
   this.setApiPathDefault();
  const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded',
  'Authorization': 'Basic ZWJlbmV6ZXJDbGllbnRJZFBhc3N3b3JkOjFTQG11M2w3OjEy'});

  const body = `username=${usuario.login}&password=${usuario.senha}&grant_type=password&client_id=ebenezerClientIdPassword`;
    console.log(this.apiPath);
      return this.http.post(this.apiPath, body, { headers })
      .toPromise()
      .then((response: any) => {
        console.log(response.access_token);
        this.armazerToken(response.access_token, response.refresh_token);
        if ( this.isAuthenticated()) {
          console.log('INICIANDO O AUTHSERVICE');
          this.authService.fazerLogin(usuario);
        } else {
          this.router.navigate(['/login']);
        }

  })
  .catch(response => {
    console.log('erro');
    console.log(response);
  });
}


armazerToken(token, refresh_token) {
  localStorage.clear();
  this.jwtPayload = this.helper.decodeToken(token);
  localStorage.setItem('token', token);
  localStorage.setItem('refresh_token', refresh_token);

  const decodedToken = this.helper.decodeToken(token);
  const tokLogin = decodedToken.login;
  const tokNome = decodedToken.nome;
  const tokMat = decodedToken.matricula;
  const tokSys = decodedToken.sistema;

  //console.log('tokSys  = ' + tokSys);
  //console.log('tokMat  = ' + tokMat);
 // console.log('nome  = ' + tokNome);
 // console.log('tokLogin  = ' + tokLogin);

  localStorage.setItem('nome', tokNome);
  localStorage.setItem('login', tokLogin);
  localStorage.setItem('matricula', tokMat);
  // tslint:disable-next-line:prefer-const
  let usuarioRede: Usuario = new Usuario();
  usuarioRede.login = tokLogin;
  usuarioRede.matricula = tokMat;
  usuarioRede.nome = tokNome;
  localStorage.setItem('usuarioRede', JSON.stringify(usuarioRede));
  console.log('FIM ARMAZENAMENTO');
}

carregarToken(): string {
  const token = localStorage.getItem('token');
  if (token) {
    console.log('RETORNANDO TOKEN SOLICIATADO PARA ISAUTHENTICATED');
    return token;
  }
}

carregarRefreshToken(): string {
  const refresh_token = localStorage.getItem('refresh_token');
  if (refresh_token) {
    console.log('RETORNANDO refresh_token SOLICIATADO PARA ISAUTHENTICATED');
    return refresh_token;
  }
}

public isAuthenticated(): boolean {
  console.log('SOLICITANDO AUTENTICAÇÃO E TOKEN PARA ISAUTHENTICATED');
  const token: string = this.carregarToken();

  if (token !== undefined) {
    console.log('passou pelo undefined DO ISAUTENTICADO  ');
 // return this.helper.isTokenExpired(token, 1200);
  return  !this.helper.isTokenExpired(token);
   // tokenNotExpired(null, token);
  }
  return false;
}

private apagarToken() {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
  localStorage.clear();
}

private apagarVariaveis() {
  localStorage.removeItem('nome');
  localStorage.removeItem('login');
  localStorage.removeItem('matricula');
  localStorage.removeItem('usuarioRede');
  localStorage.removeItem('usuario');
  localStorage.removeItem('bancoUsuario');
  localStorage.removeItem('membro');
  localStorage.clear();
}

getById(id: any): Observable<Usuario> {
  const url = `${this.apiPath}/${id}`;

  return this.http.get(url).pipe(
    map(this.jsonDataToResource.bind(this)),
    catchError(this.handleError)
  );
}

carregarUsuario(matricula: any): Usuario {

    console.log('matricula Usuario = ' + matricula);
    const bancoUsuario = localStorage.getItem('usuario');
    console.log('bancoUsuario = ' + bancoUsuario);

    if ((matricula !== null || matricula !== undefined) && (bancoUsuario === undefined || bancoUsuario === null)) {

      this.setApiPath('usuario');
          this.getById(matricula).subscribe(
              usuarioRemoto => {console.log('usuarioRemoti ' + usuarioRemoto);
                this.usuario = usuarioRemoto;
                console.log('usuarioRemoti ' + JSON.stringify(usuarioRemoto));
                localStorage.setItem('usuario', JSON.stringify(usuarioRemoto));
              },
              error => {
                 alert('Erro ao carregar registro de expiração');
              }
            );

    } else {
      if (matricula !== null || matricula !== undefined ) {
        this.usuario = JSON.parse(bancoUsuario);

      } else {
        this.usuario = JSON.parse(localStorage.getItem('usuarioRede'));
      }
    }
    setTimeout(() => console.log('AGUARDANDO....12345 '), 2000);
    return this.usuario;

}

statusExpiracao(matricula: any): void {
  this.statusUsuario = 'Acesso Não Autorizado';
  this.carregarUsuario(matricula);
  console.log('VERIFICANDO STATUS 0...');
  this.interTime = setInterval(() => {this.checkUsuario(); } , 1000);


}

checkUsuario() {
  console.log('VERIFICANDO STATUS ' + this.contador + '...');
  const user = localStorage.getItem('usuario');

    if (undefined !== user || null !== user) {
      console.log('Usuario STATUS encontrado na tentativa ' + this.contador );
        clearInterval(this.interTime);
        this.statusUsuario = this.checkStatus();
        this.mostrarUsuarioStatusEmitter.emit(this.statusUsuario );
       // return this.checkStatus();

    } else {

        if (this.contador >=  6 ) {
          clearInterval(this.interTime);
          return 'Acesso Bloqueado';
        }

      this.contador++;
    }

}

checkStatus(): string {
  console.log('Usuario STATUS Passou no interval, agora no CheckStatus' );
  if ( this.usuario.statusPerfil === 'LIBERADO' ) {
    // if (this.dataValida() && this.usuario.statusPerfil === 'LIBERADO' ) {
    this.statusUsuario = 'Acesso Válido';
  } else {
    this.statusUsuario = 'Acesso Expirado';
  }

  return this.statusUsuario;
}

/**
 * verificação feita pelo back-end
 *
dataValida(): boolean {
  console.log('Usuario STATUS Passou , agora no dataValida' );
  const user = localStorage.getItem('usuario');
    if (user !== undefined) {
      console.log('dataValida user ' + user);
      this.usuario = JSON.parse(user);

    const usuarioData = this.usuario.dataExpiracao;
        if (usuarioData !== undefined) {
            const agora = new Date().getTime();
            console.log('agora Date = ' + agora);
            const expiraEm = new Date(usuarioData).getTime();
            console.log('expira date = ' + expiraEm);
            return expiraEm >= agora;
        } else {
          return false;
        }
    }
    return false;

}*/
}
