export class Contato {

  private idContato?: number;
 // private fkCodigo: number;
  private telefone1: string;
  private telefone2: string;
  private celular: string;
  private email: string;
 // private telefone1_ddd: string;
 // private telefone2_ddd: string;
 // private celular_ddd: string;
  private atualizado: string;

  constructor() {}
}
