export class Endereco {

  public id?: number;
  public fkCodigo: number;
  public endereco: string;
  public cidade: string;
  public bairro: string;
  public uf: string;
  public cep: string;
  public complemento: string;
  public estado: string;
  public atualizado: string;

  constructor() {}
}
