/*
 * Mobile Featured
 * Used in another components.
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Membro } from '../../model';
import { MembroService } from '../../service/membro.service';

@Component({
  selector: '[angly-mobileFeatured]',
  templateUrl: './mobileFeatured.component.html',
  styleUrls: ['./mobileFeatured.component.scss']
})
export class MobileFeaturedComponent implements OnInit {

   @Input() membro : Membro;

   constructor() { }
 
   ngOnInit() {

   }

}
